<template>
  <App title="Create order" left-arrow>
    <div class="container">
      <Steps :value="curStep" :stepList="stepList" />
      <keep-alive>
        <VehicleModel
          v-if="curStep === 0"
          ref="vehicleModel"
          :carTypeCode="carType.carTypeCode"
          :featureCodeList="featureList.map((item) => item.featureCode)"
          @on-price-change="(price) => (this.totalPrice = price)"
        />
        <div v-else>
          <OrderModel
            ref="orderModel"
            :carType="carType"
            :customerInfo="customerInfo"
            :carSeriesInfo="carSeriesInfo"
            :vehicleInfo="vehicleInfo"
          />
          <Field
            v-if="type == 1"
            :value="intentionAmount"
            :max="depositAmount"
            label="意向金"
            type="number"
            input-align="right"
            readonly
            @click="showKeyboard = true"
          />
          <NumberKeyboard
            :show="showKeyboard"
            theme="custom"
            extra-key="."
            close-button-text="完成"
            @blur="showKeyboard = false"
            @input="onKeyboardInput"
            @delete="onKeyboardDelete"
          />
        </div>
      </keep-alive>
    </div>
    <Footer
      slot="footer"
      :step="curStep"
      :maxStep="stepList.length - 1"
      :totalPrice="totalPrice"
      @on-last="lastStep"
      @on-next="nextStep"
      @on-save="onSave"
      @on-cancel="onCancel"
    />
  </App>
</template>
<script>
import { Dialog, Toast, NumberKeyboard } from "vant";
import Field from "_c/dynamicForm/field";
import Steps from "./components/steps";
import Footer from "./components/footer";
import VehicleModel from "_c/business/vehicleModel";
import OrderModel from "./components/orderModel";
import { createOrder } from "@/api/order";
import { leadsbydrsave, leadsbyordersave } from "@/api/clue";
export default {
  name: "createOrder",
  components: {
    Steps,
    Footer,
    VehicleModel,
    OrderModel,
    Field,
    NumberKeyboard,
  },
  data() {
    return {
      type: 0, // 0: 大定, 1: DR
      loading: false,
      showKeyboard: false,
      curStep: 0,
      stepList: [{ label: "Model" }, { label: "Order" }],
      totalPrice: 0,
      customerInfo: {}, // 当前客户详情
      carSeriesInfo: {}, // 当前工程车型
      intentionAmount: "", // DR意向金
      carType: {}, // 所选基础车型信息
      vehicleInfo: {},
      featureList: [], // 所选车型配置
      outColorList: [], // 所选外色数据
    };
  },
  computed: {
    channel() {
      return this.$storage.get("channel");
    },
    depositAmount() {
      // 大定定金
      return this.carSeriesInfo.carDeposit;
    },
    intentionCarSeries() {
      // 意向车系
      return this.$storage.get("baseData").intention_car_series[0].value;
    },
  },
  watch: {
    loading(value) {
      if (value) {
        Toast.loading({
          duration: 0,
          message: "Loading...",
          loadingType: "spinner",
          forbidClick: true,
        });
      } else {
        Toast.clear();
      }
    },
  },
  methods: {
    onKeyboardInput(value) {
      const intentionAmount = this.intentionAmount + value;
      this.intentionAmount =
        intentionAmount.toString().match(/^\d*(\.?\d{0,2})/g)[0] || null;
      Toast(this.intentionAmount);
    },
    onKeyboardDelete() {
      let flag = true;
      if (flag) {
        // 删除掉字符串最后一个
        this.intentionAmount = this.intentionAmount.substring(
          0,
          this.intentionAmount.length - 1
        );
        Toast(this.intentionAmount);
        if (this.intentionAmount.length === 0) {
          flag = false;
          return false;
        }
      }
    },
    async onSave() {
      const data = await this.$refs.orderModel.validate();
      if (!data) return;
      if (this.type === 1) {
        if (this.intentionAmount === "") {
          return Toast("请输入意向金!");
        } else if (+this.intentionAmount > +this.depositAmount) {
          return Toast(`意向金不能大于${this.depositAmount}`);
        }
      }
      this.loading = true;

      const featureList = this.featureList.map((item) => {
        return {
          faimlyId: item.faimlyId,
          featureId: item.featureId,
          featureType: item.featureType,
          price: item.price,
          relationShip: item.relationShip,
        };
      });

      const outColorCode = this.outColorList[0].featureCode;
      const saleDlr = this.$storage.get("saleDlr");
      const userInfo = this.$storage.get("userInfo");
      const model = data;

      const options = {
        ownerType: model.purchaseType,
        purchaseType: model.purchaseType,
        name: this.customerInfo.name,
        mobile: this.customerInfo.phone,
        ownerName: model.ownerName,
        ownerPhone: model.ownerPhone,
        ownerCardType: model.ownerCardType,
        ownerCardNo: model.ownerCardNo,
        provinceId: model.provinceId,
        cityId: model.cityId,
        enterpriseName: model.enterpriseName,
        enterpriseCode: model.enterpriseCode,

        uid: this.customerInfo.name,
        superId: this.customerInfo.superId,
        consultantId: userInfo.hhrEmpid, // 销售顾问id(外围接口用hhrEmpid)
        consultantName: userInfo.name, // 销售顾问name
        consultantMobile: userInfo.phone,
        skuId: this.vehicleInfo.skuId,
        // carSeriesCode: this.carSeriesCode, // 工程车型编码
        // price: this.carType.price, // 车身价
        // acutalPrice: this.vehicleInfo.msrp, // MSRP
        // discountUniteTotal: this.vehicleInfo.discountPrice, // 统一折扣
        // invoicePrice: this.vehicleInfo.invoicePrice, // 发票金额
        // vehicleCode: this.vehicleInfo.vehicleCode, // 车辆编码16位
        // carTypeCode: this.carType.carTypeCode, // 基础车型编码
        channel: this.channel, // 渠道码
        // carColorCode: outColorCode, // 外色编码
        // featureList // 选装List
      };

      // 销售门店id
      if (saleDlr && saleDlr.length) {
        options.saleDlrId = saleDlr[0].originOrgId;
      }

      if (this.type === 0) {
        // 大定
        options.depositAmount = this.depositAmount; // 定金
        options.orderClass = "3";
        options.orderFlag = "3";
      } else if (this.type === 1) {
        // DR
        options.depositAmount = this.intentionAmount; // 意向金
        options.orderClass = "2";
        options.orderFlag = "2";
      }

      try {
        const res = await createOrder(options).finally(() => {
          this.loading = false;
        });
        if (res.success) {
          // 通知服务端创建线索和商机
          const params = {
            intentionCarSeries: this.intentionCarSeries,
            uid: this.customerInfo.uid,
            level2Code: /Android/i.test(navigator.userAgent) ? "002" : "001",
            orderNo: res.data.orderNum,
          };
          this.type === 0 ? leadsbyordersave(params) : leadsbydrsave(params);

          // 跳转支付页面
          this.$router.push({
            path: "/checkstand",
            query: {
              type: this.type,
              orderNo: res.data.orderNum,
              channel: this.channel,
              amount: options.depositAmount,
              subject: "高合" + this.carSeriesInfo.carSeriesCn,
            },
            winOpts: {
              reload: true,
            },
          });
        } else {
          if (res.code === "002007") {
            Dialog.alert({
              title: "DR提示",
              message: "您已为此客户创建过DR订单，不可以继续创建新DR订单",
            }).then(() => {
              Dialog.close();
            });
          } else {
            Toast(res.msg, { type: "fail" });
          }
        }
      } catch (e) {
        Toast("提交失败", { type: "fail" });
      }
    },
    onCancel() {
      this.$router.go(-1);
    },
    lastStep() {
      if (this.curStep > 0) {
        this.curStep--;
      }
    },
    nextStep() {
      if (this.curStep < this.stepList.length - 1) {
        this.loading = true;
        this.$refs.vehicleModel
          .validate()
          .then((res) => {
            this.carSeriesCode = res.carSeriesCode;
            this.carSeriesInfo = res.carSeriesInfo;
            this.carType = res.carType;
            this.vehicleInfo = res.vehicleInfo;
            this.featureList = res.featureCodes;
            this.outColorList = res.outColorList;
            this.curStep++;
          })
          .catch((err) => {})
          .finally(() => {
            this.loading = false;
          });
      }
    },
  },
  mounted() {
    const pageParam = this.$route.query || {};
    const { type, name, phone, superId, uid } = pageParam;

    if (!superId || !uid) {
      return Dialog.alert({
        title: "提示",
        message: "Data retrieval error!",
      }).then(() => {
        this.$router.go(-1);
      });
    }
    if (type) this.type = type;

    this.customerInfo = {
      name,
      phone,
      superId,
      uid,
    };

    if (this.curStep === 0) {
      this.$nextTick(() => {
        this.$refs.vehicleModel.init();
      });
    }
  },
};
</script>
<style lang="less" scoped>
/deep/#container {
  height: 100vh;
}
.container {
  position: relative;
  padding-top: 16px;
  padding-bottom: 24px;
  height: 100%;
  box-sizing: border-box;
  overflow: auto;
}
</style>